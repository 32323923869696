@mixin transition($x){
	-webkit-transition: all $x ease;
	   -moz-transition: all $x ease;
		-ms-transition: all $x ease;
		 -o-transition: all $x ease;
			transition: all $x ease;
}
@mixin transform($x,$y){
	-webkit-transform: translate($x,$y);
	   -moz-transform: translate($x,$y);
		-ms-transform: translate($x,$y);
		 -o-transform: translate($x,$y);
			transform: translate($x,$y);
}
@mixin border-radius($x){
	-webkit-border-radius: $x;
	   -moz-border-radius: $x;
		-ms-border-radius: $x;
		 -o-border-radius: $x;
			border-radius: $x;
}
@mixin flex-direction($x){
	-webkit-flex-direction: $x;
	   -moz-flex-direction: $x;
		-ms-flex-direction: $x;
		 -o-flex-direction: $x;
			flex-direction: $x;
}
@mixin backface-visibility($x){
	-webkit-backface-visibility: $x;
	   -moz-backface-visibility: $x;
		-ms-backface-visibility: $x;
		 -o-backface-visibility: $x;
			backface-visibility: $x;
}
@mixin scale($x){
	-webkit-transform: scale($x);
	   -moz-transform: scale($x);
		-ms-transform: scale($x);
		 -o-transform: scale($x);
			transform: scale($x);
}
@mixin greyscale($x){
	-webkit-filter: grayscale($x);
	   -moz-filter: grayscale($x);
		-ms-filter: grayscale($x);
		 -o-filter: grayscale($x);
			filter: grayscale($x);
}
@mixin translate3d($x,$y,$z){
	-webkit-transform: translate3d($x,$y,$z);
	   -moz-transform: translate3d($x,$y,$z);
		-ms-transform: translate3d($x,$y,$z);
		 -o-transform: translate3d($x,$y,$z);
			transform: translate3d($x,$y,$z);
}