@media screen and (max-height: 900px){
	.address{
		display: none
	}
}

@media screen and (max-width: 1600px){
	.absolute-logo{
		left: 50px;
		width: 150px;
	}
}/* 1600px */

@media screen and (max-width: 1350px){
	header{
		.container  	{width: 90%;}
		.nav-main a 	{padding: 15px 25px;}
	}

	.container{
	  width: 90%;
	}

	.featured{
		.info{
			width: 30%;	
			.inner 		{width: 80%;}
			p 			{width: 100%;}
		}

		.challenges .inner{width: 70%;}
	}

	.showcase{
		.info{
			h2{
				width: 40%;
				margin-left: 50px;
			}
			p 		{margin-right: 50px;}
		}
	}

	.challenge-list{
		li{padding: 35px 75px;}

		.thumbnail{
			width: 350px;
			height: 250px;
		}
		.info{
			padding-left: 375px;

			h3 				{width: 100%;}
			p 				{width: 100%;}
			.list-stats 	{width: 60%;}
		}
	}

	.dashboard-content .col-md-8 	{width: 90%;}
} /* 1350px */

@media screen and (max-width: 1200px){
	.project .detail p{
		margin-top: 10px !important;
		margin-bottom: 0;
	}
	
	.single-page-hero{
		.content{
			width: 60%;
		}
	}
	.single-project-hero{
		.content{
			width: 80%;
		}
	}
	
	.double-content{
		.text-content{
			padding-right: 400px;
		}
		.related-content{
			width: 350px;
		}

		.top-institutes .user-info{
			padding-left: 145px;
		}
	}

	.sub-nav-section 			{width: 250px;}
	.dashboard-content 		{padding-left: 300px;}

	.search-nav input[type='text'],
	.search-nav .orange-cta{
		width: 100%;
	}
} /* 1200px */

@media screen and (max-width: 1080px){
	.hero{
		z-index: 999999;
	}
	header{
		.container {
			width: 95%;
		}
		.logo{
			width: 185px;
			height: 70px;
		}
		.nav-main{
			margin-left: 0;

			a 	{padding: 15px 25px;}
		}
		.nav-login{
			margin-top: 5px;
			a 	{padding: 10px 15px;}
		}
	}

	.featured{
		position: inherit;
		height: 100vh;

		.info{
			float: left;
			position: relative;
			width: 100%;
			height: 300px;
			z-index: 99;
			
			.inner{
				position: absolute;
				width: 80%;
			}
		}
		.challenges{
			height: 100%;
			position: relative;

			.inner{
				top: 300px;
				width: 100%;
				z-index: 10;
				left: 0;
				right: 0;
			}
		}
	}

	.stats{
		top: 300px;
		height: 500px;
		position: relative;
		background: $dark;
		color: #ffffff;

		.inner{
			position: relative;
			float: left;
			width: 100%;
			top: 50%;
			@include transform(0,-50%);
		}
		.detail{
			width: 100%;
			
			&:nth-child(2){
				margin-top: 100px;
				margin-bottom: 100px;
			}

			p 	{margin-bottom: 0;}
		}
	}

	.showcase{
		margin-top: 300px;

		.info{
			overflow: hidden;

			h2{
				width: 100%;
				padding-left: 50px;
				margin-left: 0;
				margin-bottom: 25px;
			}
			p{
				float: left;
				width: 80%;
				margin-left: 50px;
			}
		}

		.grid{
			position: relative;

			.item{
				float: left;
				width: 33.33%;
				height: 300px;
				background-size: cover !important;
				background-position: center !important;
			}

			a{
				display: block;
				height: 100%;
				background: rgba(0,0,0,0.3);
				@include transition($fast);

				&:hover{
					background: none;
				}
			}
		}
	}

	.single-project{		
		.content{
			padding: 50px 325px 50px 50px;
		}
		.right-detail{
			width: 300px;

			.inner 		{padding: 50px 25px;}
		}
	}

	.challenge-list{
		.thumbnail{
			width: 350px;
			height: 225px;
		}
		.info{
			padding-left: 375px;

			.list-stats{
				display: none;
			}
		}
	}

	.sub-nav-section 			{width: 200px;}
	.dashboard-content 		{padding-left: 250px;}
} /* 1080px */

@media screen and (max-width: 920px){
	header .container{
		display: none;
	}
	
	.intro{
		width: 90%;
		
		h1{
			margin-bottom: 30px;
			line-height: 40px;
			font-size: 30px;
		}

		ul{
			margin-top: 40px;
			padding-left: 0;

			li{
				width: 40%;
				margin: 0 !important;
				margin-left: 6% !important;
			}
		}
	}

	.dis-menu{
		display: none;
		background: #000000;
		position: fixed;
		bottom: 42px;
		left: 0;
		width: 33%;
		z-index: 99999;

		a{
			border-bottom: 1px solid #333333;
			display: block;
			text-align: center;
			color: rgba(255,255,255,0.6);
			padding: 15px 0;
			text-transform: uppercase;
			font-size: 11px;
		}
	}
	.res-menu{
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 9999 !important;
		border-top: 1px solid #e0e0e0;
		background: #ffffff;

		ul{
			float: left;
			width: 100%;
			
			li{
				border-right: 1px solid #e0e0e0;
				float: left;
				width: 33%;
				
				&:last-child 	{border-right: none;}

				a{
					text-align: center;
					padding: 15px 0;
					display: block;
					color: $grey;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: $bold;
					letter-spacing: 1px;
					cursor: pointer;
					@include transition($fast);

					i{
						margin-right: 5px;
					}

					&.active{
						background: #000000;
						color: #ffffff;

						i{
						margin-right: 5px;
							transform: rotate(180deg);
						}
					}
				}
				.dis-menu{
					position: absolute;
					height: 150px;
					background: #000000;
				}
			}
		}
	}
	.featured{
		.info 		{height: 400px;}
		
		.challenges{
			height: 100%;
			position: relative;
			margin-top: 100px;
		}

		.project{
			position: static;
			width: 100%;
			overflow: hidden;

			&.small 			{height: 350px;}
			&.big 			{height: 350px;}
			&.right 			{left: 0; top: 300px;}
			&.left 			{left: 0;}

			&:nth-child(3) 	{top: 0;}
			&:nth-child(4) 	{top: 0;}

			.info 			{bottom: 25px;}

			.detail .content{padding: 20px 20px;}
		}
	}
	.stats 	{top: 790px;}

	.showcase{
		margin-top: 790px;

		.grid .item{
			width: 100%;
			height: 250px;
		}
	}
	.challenge-list{
		li{
			padding: 30px;
		}
		.thumbnail{
			display: block;
			width: 100%;
			height: 250px;
			position: static;
		}
		.info{
			position: static;
			padding-top: 10px;
			padding-left: 0;

			.list-stats{
				position: static;
				display: block;
				float: left;
				margin-top: 25px;
				width: 100%;

				div{
					width: 33.33%;
				}

				strong{
					display: block;
					text-align: center;
					position: relative;
					font-weight: $bold;
					color: #000000;
					font-size: 14px;
					margin-bottom: 10px;

					em{
						position: absolute;
						top: 0;
						left: 15%;
						font-size: 10px;
						font-weight: $normal;
					}
					.dot{
						width: 7px;
						height: 7px;
						top: 4px;
						left: 32%;
					}
				}
				span{
					font-size: 11px;
					font-weight: $bold;
				}
			}
		}
	}

	.single-project{
		.content{
			position: static;
			padding: 50px 20px;
			min-height: auto;
		}
		.right-detail{
			position: static;
			width: 100%;
			height: 500px;
		}
	}

	.double-content{
		height: auto;
		margin-bottom: 50px;
		position: relative;
		float: left;

		.text-content{
			padding-top: 25px;
			padding-right: 30px;
			padding-bottom: 25px;
			padding-left: 30px;

			strong 		{line-height: 20px;}
		}
		.related-content{
			border-top: 1px solid lighten($grey, 30%);
			border-left: none;
			width: 100%;
			position: static;
			float: left;
		}
	}

	.contact-hero{
		.content{
			width: 90%;
		}

		form{
			padding: 25px 25px 75px 25px;
		}

		form input, 
		form textarea{
			width: 93%;
		}
	}

	.sponsors{
		ul{
			padding: 25px 0;

			li{
				float: left;
				width: 50%;		
			}
		}
		a 		{background-size: 50% !important;}
	}
	footer{
		.footer-cta{
			.container{
				position: relative;
			}
			
			h6{
				position: relative;
				padding: 40px 0 80px 0;
				font-size: 18px;
			}

			a{
				top: 58%;
				left: 50%;
				right: auto;
				padding: 10px 15px;
				font-size: 12px;
				font-weight: $bold;
				@include transform(-50%, 0);
			}
		}

		.footer-content{
			padding: 50px 0;
			float: left;
			width: 100%;

			.contact{
				float: left;
				padding-left: 10px;
				width: 100%;
				margin-top: 130px;
			}
			.social{
				float: left;
				width: 100%;
				text-align: left;
			}
		}

		.copyright{
			margin-top: 50px;
			color: #575757;
			text-align: left;
			
			p{
				font-size: 12px;
				line-height: 25px;
			}
			a 	{color: #ffffff;}
		}
	}

	.sub-nav-section{
		position: static;
		float: left;
		width: 100%;
		margin-bottom: 25px;

		.sub-nav{
			box-sizing: border-box;
			padding: 0 10px;
			margin-top: 0;
			text-align: center;

			a{
				display: inline-block;
				border: 1px solid blue;
				padding: 10px 20px;
    			font-size: 10px;
    			margin-bottom: 5px;
				border: 1px solid #666666;

				&.active{
					border: 1px solid $orange;
				}
			}

			a.hide-on-res 		{display: none;}
		}

		.search-nav 			{display: none}
	}

	.dashboard-content{
		padding: 0 50px;

		.col-md-8 		{width: 100%;}
	}

	.advanced-search{
		flex-direction: column-reverse;

		.left-search 		{flex: 1;}
		.right-search{
			border-left: none;
			border-bottom: 1px solid #e5e5e5;
			margin-bottom: 25px;
			padding-bottom: 25px;

			.check{
				display: block;
				width: 12px; height: 12px;
				margin-right: 10px;
				margin-bottom: 0;
				background: #cccccc;
			}
			label{
				cursor: pointer;
				font-size: 14px;
				color: #666666;
			}
			.csscheck:checked + .check{
				background: $orange;
			}
		}
	}
	.filter-list{
		display: flex;
		flex-wrap: wrap;

		li 		{width: 33%;}
	}

	.social-filter{
		li{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		strong 			{display: inline;}
		label 			{display: block;}
	}
} /* 920px */

@media only screen and(max-width: 720px){
	.dashboard-content{
		padding: 0 15px;
		
		.col-md-8 		{width: 100%;}
	}
}

@media only screen and(max-width: 500px){
	.filter-list li 		{width: 50%;}

	.social-filter{
		li{
			flex-direction: column;
			align-items: flex-start;
		}
		.inputs,
		.input-group,
		input{
			width: 100%;
		}
		
		strong 			{display: inline;}
		label 			{display: block;}
	}
}