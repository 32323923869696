/* Grid
-------------------- */
*:focus {
    outline: none;
}  
* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.section{
  float: left;
  width: 100%;
}
.container{
  width: 1080px;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.column {
  float: left;
}

body{
  font-family: $para;
  box-sizing: border-box;
}
h1{
  font-size: 42px;
  font-family: $para;
  font-weight: $bold;
  margin-bottom: 50px;
}
h2{
  font-size: 36px;
  line-height: 40px;
  font-family: $para;
  font-weight: $fat;
  margin-bottom: 40px;
  color: #333333;
}

h4{
  margin-bottom: 20px;
  color: #333333;
  font-weight: $fat;
  font-size: 15px;
}
.ball{
  background: #000000;
}
p{
  font-size: 14px;
  line-height: 24px;
  color: $grey;
}
a{
  text-decoration: none;
}
.flexbox{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

a.registration-link{
  display: block;
  margin: 20px 0;
  color: darken($grey, 10%);
  font-weight: $bold;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;

  &:hover{
    color: darken($grey, 20%);
  }
}
.parallax{
  position: fixed;
  height: 100vh;
  width: 100%;
  background-size: cover !important;
  background-position: center !important;
  top: 0;
  left: 0;
  bottom: 0;
  //z-index: -1;
}

/* Alerts
========================= */
.alert{
  margin-bottom: 15px;
}
.alert-danger{
  color: red;
}
.alert li{
  margin-bottom: 5px;
}

/* Buttons
========================= */
.orange-cta{
  display: inline-block;
  background: $orange;
  color: #ffffff;
  text-transform: uppercase;
  padding: 12px 25px;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: $para;
  font-weight: $fat;
  border: none;
  @include transition($fast);
  cursor: pointer;

  &:hover{
    background: darken($orange, 5%);
  }
}

.btn-submit{
  background: $orange;
  margin-top: 25px;
  margin-left: 450px;
  color: #ffffff;
  font-size: 12px;
  font-weight: $fat;
  display: inline-block;
  padding: 10px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  @include transition($fast);

  &:hover{
    background: lighten($orange, 10%);
  }
}

/* Table
========================= */
table{
  float: left;
  margin-top: 25px;
  width: 100%;

  thead{
    text-align: left;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: $bold;
    color: $grey;
    
    th    {padding: 10px;}
  }

  tbody{

    tr{
      &:nth-child(odd){
        background: lighten($grey, 35%);
      }
    }
    td{
      padding: 10px;
      font-size: 14px;
    }

    a{
      color: #000000;
    }
  }

}