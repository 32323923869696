/* Fonts
-------------------- */
$title: 		'Oswald', sans-serif;
$para:			'Open Sans', sans-serif;

/* Font Weights
-------------------- */
$fat: 			800 !default;
$bold: 			700 !default;
$normal: 		400 !default;
$light: 		300 !default;

/* Timings
-------------------- */
$fast: 			.2s !default;
$medium: 		.35s !default;
$slow: 			.7s !default;

/* Colors
-------------------- */
$bordeaux: 		#6d1b29 !default;
$grey:			#666666 !default;
$dark-grey: 	#7d7d7d !default;
$light-grey: 	#f6f6f6 !default;
$dark: 			#272727 !default;
$line-grey:		#e0e0e0 !default;
$red: 			#c5060e;
$yellow: 		#fdaa2a;
$orange: 		#d57820;
$green: 		#1ab422;
