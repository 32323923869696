/*!
Theme Name: ABCommunication Platform
Version: 1.08
Latest update: May 28, 2018
Author: Glitch
Website: http://weareglitch.io
*/
// imports
@import "reset";
@import "variables";
@import "mixins";
@import "standards";
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/trumbowyg/dist/ui/sass/trumbowyg";

.form-group{
	float: left;
	width: 100%;
	position: relative;
	margin-bottom: 10px;

	label{
		display: inline-block;
		float: left;
		width: 30%;
		font-size: 14px;
		font-weight: $normal;
		letter-spacing: 1px;
		color: $grey;
	}
	input,
	textarea{
		font-size: 14px;
		color: $grey;
		width: 300px;
		padding: 5px 5px;
		border: 1px solid $grey;
		font-family: $para;
		@include border-radius(0);
		@include transition($fast);

		&:focus{
			border: 1px solid black;
		}
	}
}
.error-emails{
	list-style-type: disc;
	padding-left: 25px;

	li{
		font-size: 14px;
		line-height: 24px;
		color: #666666;
		padding: 0;
	}
}
.reg-type, .reg-type-terms, .admin-reg-type{
	float: left;
	position: relative;
	width: 100%;
	padding-left: 30px;
	font-size: 13px;
	font-weight: $normal;
	color: $grey;
	height: 15px;
	margin-bottom: 10px;
	cursor: pointer;

	.check{
		position: absolute;
		border: none;
		background: darken($light-grey, 5%);
		width: 15px;
		height: 15px;
		left: 5px;
		transition: all .4s ease;

		&.active{
			border-top: none;
			border-left: none;
			border-bottom: 3px solid $orange;
			border-right: 3px solid $orange;
			background: none;
			transform: rotate(45deg);
			left: 9px;
			height: 10px;
			width: 6px;
		}
	}
}
.edit-btn{
	margin-top: 0 !important;
	float: right;
	border: 1px solid red;
}
.reg-section{
	position: absolute;
	left: 50%;
	width: 50%;
	font-size: 30px;
	font-weight: $light;
	@include transform(-50%, 0);

	.form-control{
		margin-left: 10px;
		margin-right: 10px;
		font-size: 25px;
		color: $grey;
		font-weight: $normal;
		background: transparent;
		width: 250px;
		padding: 5px 5px;
		border: none;
		border-bottom: 1px solid $grey;
		font-family: $para;
		@include border-radius(0);
		@include transition($fast);

		&:focus{
			border: none;
			border-bottom: 1px solid #ffffff;
		}
	}
	
	ul.reg-options{
		text-align: center;
		margin-top: 25px;

		li{
			display: inline-block;
			cursor: pointer;
			font-size: 14px;
			font-weight: $normal;
			text-transform: uppercase;
			padding: 10px 25px;
			margin-right: 10px;
			color: rgba(255, 255, 255, .3);
			border: 1px solid rgba(255, 255, 255, .5);
			@include transition($fast);
			margin-bottom: 10px;

			&:last-child{
				margin-right: 0;
			}
			&:hover{
				color: rgba(255, 255, 255, .7);
				border: 1px solid rgba(255, 255, 255, .7);
			}

			&.active{
				color: rgba(255, 255, 255, 1);
				border: 1px solid rgba(255, 255, 255, 1);
			}
		}
	}
}

.choises{
	float: left;
	width: 100%;

	ul{
		float: left;
		width: 100%;
	}
	li{
		position: relative;
		padding: 5px 10px 10px 30px;
		box-sizing: border-box;
		float: left;
		width: 50%;
		font-size: 13px;
		font-weight: $normal;
		color: $grey;
		cursor: pointer;
	}
	.check{
		position: absolute;
		border: none;
		background: darken($light-grey, 5%);
		width: 15px;
		height: 15px;
		left: 5px;
		transition: all .4s ease;

		&.active{
			border-top: none;
			border-left: none;
			border-bottom: 3px solid $orange;
			border-right: 3px solid $orange;
			background: none;
			transform: rotate(45deg);
			left: 9px;
			height: 10px;
			width: 6px;
		}
	}
}
.res-menu, .dis-menu{
	display: none;
}
.custom-checkbox{
	cursor: pointer;

	span{
		border: 1px solid #999999;
		display: inline-block;
		margin-right: 10px;
		height: 10px; width: 10px;
		transition: all .25s ease;
	}
	em{
		color: #999999;
		transition: all .25s ease;
		
		.select{
			opacity: 0;
			transition: all .25s ease;
		}
	}
}
.the-select:checked + label{
	span{
		border: 1px solid $orange;
		background: $orange;
	}
	em{
		color: $orange;
		.select{
			opacity: 1;
		}
	}
}
.orange-cta.second{
	float: left !important;
}
.table-margin{
	margin-bottom: 25px !important;
}
header{
	position: relative;
	display: block;
	float: left;
	height: 100vh;
	z-index: 10;
	font-family: $para;

	.container {
		width: 85%;
		padding-top: 10px;
	}

	.logo{
		float: left;
		width: 200px;
		padding-top: 10px;

		img {
			max-width: 100%;
			height: auto;
		}
		a 			{margin-top: 0px;}
	}
	
	a{
		margin-top: 10px;
		display: inline-block;
		font-weight: $bold;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.nav-main{
		float: left;
		margin-left: 50px;
		width: 50%;
		
		ul{
			float: left;
			width: 100%;

			li{
				float: left;
				display: inline-block;
			}
		}

		ul.hidden-menu{
			display: none;
			width: 210px !important;
			float: left;

			li{
				float: left;
				width: 100%;

				a{
					margin: 0;

					&:hover{
						color: $orange;
					}
				}
			}
		}

		li.dropdown:hover ul.hidden-menu{
			display: block !important;
		}

		a{
			display: block;
			padding: 15px 50px;
			font-size: 13px;
			color: rgba(255,255,255,.7);

			&:hover 	{color: rgba(255,255,255,1);}
		}
	}
	.nav-login{
		float: right;
		height: 80px;

		a{
			padding: 15px 35px;
			font-size: 12px;
			color: rgba(255,255,255,1);
			transition: all $slow ease;

			&:first-child{
				background: #ee271e;
			}
		}
	}
}

a.classic-link 	{color: $orange;}

.container {
	position: relative;
    z-index: 2;
    color: #FFF;
}
.login-control{
	border: none;
	border-bottom: 1px solid rgba(255,255,255,0.3);
	background: transparent;
	font-size: 20px;
	padding: 10px 15px;
	display: block;
	width: 80%;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	@include transition($medium);

	&:focus{
		border-bottom: 1px solid rgba(255,255,255,1);
	}
}
.inline-control{
	border: none;
	border-bottom: 1px solid rgba(255,255,255,0.3);
	background: transparent;
	font-size: 30px;
	font-weight: 300;
	padding: 5px;
	display: inline-block;
	width: auto;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	@include transition($medium);

	&:focus{
		border-bottom: 1px solid rgba(255,255,255,1);
	}
}
.close-panel{
	color: rgba(255,255,255,0.5);
	font-size: 30px;
	position: absolute;
	top: 25px;
	right: 25px;
	@include transition($fast);

	&:hover{
		color: #ffffff;
	}
}
.dash-bg{
	position: fixed;
	top: 0; right: 0;
	bottom: 0; left: 0;
	z-index: -1;
	background: #eeeeee;
}
.dashboard-content{
	float: left;
	box-sizing: border-box;
	width: 100%;
	padding-left: 350px;

	.col-md-8 	{transition: all .2s ease;}
}
.sub-nav-section{
	float: left;
	// position: fixed;
	position: absolute;
	top: 120px; bottom: 0;
	width: 300px;
}
.sub-nav{
	width: 100%;
	margin-top: 20px;

	a{
		box-sizing: border-box;
		display: block;
		padding: 20px 25px;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: $bold;
		color: $grey;

		&:last-child 	{margin-right: 0}
		&:hover 		 	{color: #333333;}
		&.active 		{color: $orange;}
	}
}
.search-nav{
	box-sizing: border-box;
	float: left;
	width: 100%;
	padding: 0 25px;
	margin-top: 25px;

	input[type='text']{
		float: left;
		margin: 0;
		padding: 5px 5px;
		width: 80%;
		box-sizing: border-box;
	}
	.orange-cta{
		box-sizing: border-box;
		border: none !important;
		padding: 6px 10px;
		font-size: 10px;
		width: 20%;
	}
}
#login-panel,
#register-panel{
	position: fixed;
	top: 0; right: 0;
	bottom: 0; left: 0;
	z-index: 99;
	display: none;
	background: rgba(0,0,0,0.95);

	.content{
		position: absolute;
		width: 550px;
		top: 50%;
		left: 50%;
		@include transform(-50%,-50%);
	}
	h2{
		text-align: center;
		margin-bottom: 50px;
		color: #ffffff;
		text-transform: uppercase;
	}
	form{
		margin: 25px 0;
		position: relative;
		height: 150px;

		input[type="submit"]{
			border: 1px solid rgba(255,255,255,0.5);
			@include border-radius(0);
			background: #333333;
			color: rgba(255,255,255,0.5);
			padding: 10px 35px;
			text-transform: uppercase;
			font-weight: $bold;
			bottom: 0; right: 8%;
			cursor: pointer;
			position: absolute;
			@include transition($fast);

			&:hover{
				background: #ffffff;
				color: #333333;
				border: 1px solid #ffffff;
			}
		}
	}
}
#login-panel{
	form a{
		display: inline-block;
		color: rgba(255,255,255,0.5);
		font-size: 12px;
		margin-left: 7%;

		&:hover{color: #ffffff;}
	}
}
#register-panel{
	color: #ffffff;

	.content{
		width: 80%;
		text-align: center;
	}

	h2{
		margin-bottom: 100px;
	}

	.step{
		font-size: 30px;
		font-weight: 300;
		display: none;
		@include transition($medium);

		&.active{
			display: block;
		}
	}

	.form-nav{
		position: absolute;
		bottom: 50px;
		width: 100%;
		text-align: center;

		a{
			border: 1px solid rgba(255,255,255,0.5);
			display: inline-block;
			padding: 10px 20px;
			font-weight: 300;
			text-transform: uppercase;
			font-size: 12px;
			color: rgba(255,255,255,0.5);
			@include transition($fast);
			
			i{
				margin-right: 0;
				@include transition($medium);
			}
			&:hover{
				color: rgba(255,255,255,1);
				border: 1px solid rgba(255,255,255,1);
			}			
			
			&:first-child i 	{margin-right: 10px;}
			&:last-child i 		{margin-left: 10px;}
			&:first-child:hover i{
				color: white;
				margin-right: 20px;
			}
			&:last-child:hover i{
				color: white;
				margin-left: 20px;
			}
		}
	}
}

.hero{
	display: block;
	float: left;
	height: 100vh;
	width: 100%;
	z-index: 0;
	position: relative;
	background-size: cover !important;
	background-position: center !important;

	.dark-filter{
		position: absolute;
		z-index: 1;
		top: 0;	right: 0;
		bottom: 0; left: 0;
		background: rgba(0,0,0,0.7);
	}
}

.intro{
	position: absolute;
	width: 50%;
	top: 50%;
	left: 50%;
	z-index: 10;
	text-align: center;
	@include transform(-50%, -50%);
	
	h1{
		color: #ffffff;
		font-weight: $bold;
		font-family: $para;
		margin-bottom: 40px;
		line-height: 45px;
		font-size: 30px;
	}

	p{
		color: #ffffff;
		font-weight: $light;
		font-size: 16px;
		line-height: 24px;
	}

	ul{
		margin-top: 80px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		padding: 0;
		float: left;
		text-align: center;

		li{
			display: inline-block;
			width: 150px;

			&:first-child{
				margin-right: 60px;
			}
		}

		a{
			display: inline-block;
			width: 100%;
			text-align: center;
			color: rgba(255,255,255,0.7);
			text-transform: uppercase;
			padding: 15px 0;
			transition: all $medium ease;
			font-size: 12px;
			letter-spacing: 1.5px;
			font-family: $para;
			font-weight: $fat;

			&.influ{
				background: #ee271e;
				border: 1px solid #ee271e;
				color: #ffffff;

				&:hover{
					background: darken(#ee271e, 15%);
					border: 1px solid darken(#ee271e, 15%);
				}
			}
			&.compa{
				opacity: 0.75;

				&:hover{
					opacity: 1;
				}
			}
		}
	}
}
.inner-hero{
	float: left; width: 100%;
	height: 350px;
	background-size: cover !important;
	background-position: center !important;
}
ul.stat-list{
	float: left;
	width: 100%;
	box-sizing: border-box;
	margin: 10px 0;

	li{
		float: left;
		width: 33.33%;
		box-sizing: border-box;
	}

	strong{
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
		color: $grey;
	}
	span.title{
		display: block;
		text-align: center;
		font-size: 36px;
	}
}

.back-title-section{
	h4{
		font-size: 24px;
		color: $orange;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 50px;
	}
}
.featured{
	position: relative;
	height: 100vh;

	.info{
		float: left;
		position: relative;
		width: 40%;
		height: 100%;
		z-index: 99;
		
		.inner{
			position: absolute;
			width: 70%;
			top: 50%;
			left: 50%;
			@include transform(-50%, -50%);
		}
		p{
			margin-bottom: 50px;
			width: 90%;
		}
	}
	.challenges{
		height: 100%;
		position: relative;

		.inner{
			position: absolute;
			// top: 100px;
			top: 0;
			height: 100%;
			width: 60%;
			z-index: 10;
			right: 0;
		}

		.empty-challenge-panel{
			position: absolute;
			top: 0;
			height: 100%;
			width: 60%;
			z-index: 10;
			right: 0;
			background-size: cover !important;
			background-position: center !important;
		}
	}

	.project{
		position: absolute;
		width: 50%;
		overflow: hidden;
		background-size: cover !important;
		background-position: center !important;

		&.small 		{height: 45%;}
		&.big 			{height: 55%;}
		&.right 		{right: 0;}
		&.left 			{left: 0;}

		&:nth-child(3) 	{top: 45%;}
		&:nth-child(4) 	{top: 55%;}

		a{
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
			background: rgba(0,0,0,0.3);
		}
		.info{
			position: absolute;
			left: 50%;
			bottom: 40px;
			background: #ffffff;
			width: 80%;
			height: auto;
			padding: 25px 30px;
			z-index: 2;
			@include transform(-50%,0);
			@include transition($medium);

			p{
				width: 100%;
				margin: 0;
				padding: 0;
				font-weight: 700;
				color: #333333;
				@include transition($medium);
			}

			.dot{
				@include border-radius(50%);
				width: 18px;
				height: 18px;
				position: absolute;
				left: -9px;
				top: -9px;

				&.green 		{background: $green;}
				&.yellow 	{background: $yellow;}
				&.red 		{background: $red;}
				&.disabled 	{background: #999999;}
			}
		}
		.detail{
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			width: 100%;
			height: 0;
			opacity: 0;
			background: #ffffff;
			z-index: 40;
			@include transition($medium);
			
			.content{
				position: relative;
				padding: 50px;
				height: 80%;
			}

			.title{
				h4{
					width: 70%;
					font-weight: 700;
					color: #333333;
					font-size: 18px;
					line-height: 23px;
				}
				img{
					float: right;
					width: 20%;
				}
			}

			p{
				font-family: $para;
				font-size: 14px;
				line-height: 24px;
				color: #272727;
				display: block;
				margin-top: 30px;
				margin-bottom: 50px;
			}

			ul{
				position: absolute;
				text-align: center;
				width: 100%;
				left: 0;
				bottom: 60px !important;
			}
			li{
				display: inline-block;

				strong{
					color: #333333;
					display: block;
					font-weight: 700;
					font-size: 30px;
				}
				span{
					color: #999999;
					display: block;
					margin-top: 10px;
					text-transform: uppercase;
					font-weight: 700;
					font-size: 13px;
				}
				
				&:first-child{
					margin-right: 35px;
					position: relative;
				}
				&:first-child strong{
					position: relative;

					.dot{
						@include border-radius(50%);
						width: 12px;
						height: 12px;
						position: absolute;
						left: -5px;
						top: 50%;
						transform: translateY(-50%);

						&.green 		{background: $green;}
						&.yellow 	{background: $yellow;}
						&.red 		{background: $red;}
						&.disabled 	{background: #999999;}
					}
				}
				&:last-child strong::before{
					content: '$';
					margin-right: 10px;
					display: inline-block;
				}
			}
		}

		&:hover{
			.info{
				bottom: 0;
				width: 85%;
				
				.dot, p 	{opacity: 0;}
			}

			.detail{
				height: 100%;
				opacity: 1;
			}
		}
	}
}

.stats{
	height: 500px;
	position: relative;
	background: $dark;
	color: #ffffff;

	.inner{
		position: relative;
		float: left;
		width: 100%;
		top: 60%;
		@include transform(0,-50%);
	}
	.detail{
		float: left;
		width: 33.33%;
		text-align: center;
		
		p{
			font-weight: $bold;
			font-family: $title;
			font-size: 42px;
			margin-bottom: 30px;
			color: #ffffff;
			width: 100%;
			position: relative;
			height: 50px;

			span{
				font-size: 20px;
				position: relative;
				top: -25px;
			}
		}

		h4{
			font-weight: $fat;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: 13px;
			font-family: $para;
			color: rgba(255,255,255,.5);
		}
	}
}

.showcase{
	.info{
		position: relative;
		padding-top: 100px;
		padding-bottom: 100px;

		h2{
			float: left;
			width: 30%;
			margin: 0;
			margin-left: 100px;
		}
		p{
			float: right;
			width: 500px;
			margin: 0;
			margin-right: 100px;
		}
	}

	.grid{
		position: relative;

		.item{
			float: left;
			width: 33.33%;
			height: 300px;
			background-size: cover !important;
			background-position: center !important;
		}

		a{
			display: block;
			height: 100%;
			background: rgba(0,0,0,0.3);
			@include transition($fast);

			&:hover{
				background: none;
			}
		}
	}
}

.overview{
	.title-intro{
		padding: 50px 0;
	}

	ul{
		list-style-type: none;
		float: left;
		width: 100%;

		li{
			float: left;
			width: 25%;
			height: 300px;
			border: 1px solid black;
			position: relative;
		}

		a{
			height: 100%;
			display: block;
		}
	}
}

.challenge-list{
	li{
		box-sizing: border-box;
		float: left;
		width: 100%;
		border-bottom: 1px solid #e7e7e7;
		padding: 50px 100px;

		&:last-child{
			border-bottom: 0;
			margin-bottom: 75px;
		}
	}

	a{
		display: block;
		float: left;
		width: 100%;
		position: relative;
	}

	.thumbnail{
		width: 400px;
		height: 250px;
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover !important;
		background-position: center !important;
	}
	.info{
		padding-left: 460px;
		box-sizing: border-box;
		min-height: 250px;
		width: 100%;
		position: relative;

		h3{
			font-weight: $fat;
			color: #333333;
			font-size: 20px;
			line-height: 33px;
			width: 600px;
		}
		p{
			margin-top: 25px;
			width: 600px;
		}

		.list-stats{
			position: absolute;
			width: 600px;
			bottom: 0;

			div{
				float: left;
				width: 30%;
			}
			strong{
				display: block;
				text-align: center;
				position: relative;
				font-weight: $fat;
				color: #333333;
				font-size: 20px;
				margin-bottom: 10px;

				em{
					position: absolute;
					top: 0;
					left: 20%;
					font-size: 15px;
					font-weight: $bold;
				}
				.dot{
					position: absolute;
					width: 10px;
					height: 10px;
					top: 5px;
					left: 35%;
					@include border-radius(50%);

					&.green 	{background: $green;}
					&.orange 	{background: $orange;}
					&.red 		{background: $red;}
					&.disabled {background: #999999;}
				}
			}
			span{
				display: block;
				text-align: center;
				color: $grey;
				font-size: 13px;
				letter-spacing: 1px;
				font-weight: $bold;
				text-transform: uppercase;
			}
		}
	}
}

.popup-filter{
	position: fixed;
	top: 0; right: 0;
	bottom: 0; left: 0;
	background: rgba(0,0,0,0.7);
	z-index: 999999998;
	display: none;
}
.login-popup{
	position: fixed;
	top: 50%;
	left: 50%;
	@include transform(-50%,-50%);
	background: #ffffff;
	padding: 50px 75px;
	z-index: 999999999;
	display: none;
	
	.centering{
		text-align: center;
	}

	p 		{margin-bottom: 50px;}

	a{
		display: inline-block;
		font-size: 14px;
		padding: 10px 25px;

		&.blank{color: #666666;}
		&.orange{
			background: $orange;
			color: #ffffff;
		}
	}
}

.error-bg{
	width: 100%;
	height: 100vh;
	position: absolute;
	background-size: cover !important;
	background-position: center !important;

	.dark-filter{
		position: absolute;
		top: 0; right: 0;
		bottom: 0; left: 0;
		background: #000000;
		opacity: .5;
		z-index: 0;
	}

	.inner{
		width: 80%;
		position: absolute;
		top: 50%; left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
	}
	h1{
		color: #ffffff;
		font-weight: 400;
		font-size: 30px;

		span{
			font-weight: 700;
		}
	}

	a{
		font-weight: 700;
		font-size: 65px;
		color: #ffffff;
		transition: all .2s ease;

		&:hover 		{color: $orange;}
	}
}

.advanced-search{
	display: flex;

	.left-search{
		flex: 2;
		padding: 10px;
	}
	.right-search{
		border-left: 1px solid #e5e5e5;
		padding: 10px;
		min-width: 265px;
		flex: 1;

		h3{
			display: block;
			margin-bottom: 15px;
		}

		.check{
			display: block;
			width: 12px; height: 12px;
			margin-right: 10px;
			margin-bottom: 0;
			background: #cccccc;
		}
		label{
			cursor: pointer;
			font-size: 14px;
			color: #666666;
		}
		.csscheck:checked + .check{
			background: $orange;
		}
	}
}

.filter-list{
	list-style-type: none;
	margin-bottom: 30px;
	padding-left: 10px;

	li{
		display: block;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}
}

.social-filter{
	padding-left: 10px;
	margin-bottom: 35px;

	li{
		display: block;
		margin-bottom: 0;

		&.active{
			margin-bottom: 25px;

			label.main{
				font-weight: 700;
				color: #000000 !important;
			}

			.inputs{
				display: flex;
			}
		}
	}
	label.main{
		display: inline-block;
		font-weight: 400;
		font-size: 14px !important;
		margin: 0;
		margin-bottom: 5px;
	}

	label 			{font-size: 12px !important;}

	.check{
		display: inline-block !important;
		margin-right: 5px !important;
	}

	.inputs{
		display: none;

		input 		{padding: 3px;}
	}

	
}

.result-list{
	li{
		display: block;
		padding: 10px 0;
	}
	a{
		display: block;
		color: #999999;
		transition: color .25s ease;

		&:hover 		{color: $orange;}

		small{
			display: inline-block;
			margin-right: 10px;
			width: 60px;
			font-size: 12px;
		}
	}
}

.sponsors{
	ul{
		float: left;
		width: 100%;

		li{
			float: left;
			width: 25%;

			
		}
	}
	a{
		display: block;
		height: 200px;
		background-size: 40% !important;
		background-position: center !important;
		opacity: 0.3;
		@include greyscale(100%);
		@include transition($fast);

		&:hover{
			opacity: 1;
			@include greyscale(0);
		}
	}
}
.single-project-hero{
	float: left;
	width: 100%;
	position: relative;
	height: 100vh;
	top: -80px;
	background-size: cover !important;
	background-position: cover !important;
	
	.filter{
		position: absolute;
		top: 0; right: 0;
		bottom: 0; left: 0;
		background: rgba(0,0,0,0.8);
	}

	.content{
		position: absolute;
		top: 50%;
		width: 40%;
		left: 7%;
		@include transform(0,-50%);

		h1{
			font-weight: $bold;
			line-height: 50px;
			color: #ffffff;
		}
	}

	.share{
		margin-top: 60px;
		float: left;
		width: 100%;

		strong{
			float: left;
			width: 100%;
			color: $grey;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: $bold;
			font-size: 13px;
		}

		ul{
			margin-top: 20px;
			float: left;
			width: 100%;
		}
		li{
			float: left;
			font-size: 20px;
			position: relative;
			height: 50px;

			a{
				position: absolute;
				display: inline-block;
				width: 50px;
				height: 50px;
				@include border-radius(50%);
				@include transition($fast);

				i{
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(-50%, -50%);
				}
			}

			&:first-child a{
				background: #3d5b97;

				&:hover{
					background: darken(#3d5b97, 10%);
				}
			}
			&:nth-child(2) a{
				background: #1bafed;
				left: 80px;

				&:hover{
					background: darken(#1bafed, 10%);
				}
			}
		}
	}

	.go-down{
		position: absolute;
		display: inline-block;
		color: rgba(255,255,255,0.6);
		font-size: 20px;
		bottom: 50px;
		left: 50%;
		@include transform(-50%, 0);
		@include transition($fast);

		&:hover{
			color: rgba(255,255,255,1);
		}
	}
}
.single-project{
	position: relative;
	top: -80px;
	background: #ffffff;
	margin-bottom: -250px;
	
	.content{
		padding: 50px 550px 50px 50px;
		min-height: 500px;
	}
	.right-detail{
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 500px;
		border-left: 1px solid $line-grey;

		.inner{
			padding: 50px;
			position: relative;
		}
		.orange-cta{
			margin-top: 50px;
			text-align: center;
			line-height: 20px;
		}
	}
}
.single-page-hero{
	float: left;
	width: 100%;
	position: relative;
	height: 700px;
	top: -80px;
	background-size: cover !important;
	background-position: center !important;
	
	.filter{
		position: absolute;
		top: 0; right: 0;
		bottom: 0; left: 0;
		background: rgba(0,0,0,0.7);
	}

	.content{
		position: absolute;
		top: 50%;
		width: 500px;
		left: 7%;
		@include transform(0,-50%);

		h4{
			margin: 0;
			color: $grey;
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1px;
		}

		h1{
			font-weight: $bold;
			margin-top: 20px;
			line-height: 45px;
			margin-bottom: 50px;
			color: #ffffff;
		}
		p{
			color: #999999;
		}
	}

	.go-down{
		position: absolute;
		display: inline-block;
		color: rgba(255,255,255,0.6);
		font-size: 20px;
		bottom: 30px;
		left: 50%;
		@include transform(-50%, 0);
		@include transition($fast);

		&:hover{
			color: rgba(255,255,255,1);
		}
	}
}
.single-page{
	position: relative;
	top: -80px;
	background: #ffffff;
	margin-bottom: -250px;
	
	.content{
		padding: 100px 0;
		min-height: 500px;
	}
	.type{

		h3{
			font-weight: $bold;
			font-family: $para;
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
}
.contact-hero{
	float: left;
	width: 100%;
	position: relative;
	min-height: 100vh;
	top: -80px;
	margin-bottom: -230px;
	background-size: cover !important;
	background-position: cover !important;
	
	.filter{
		position: absolute;
		top: 0; right: 0;
		bottom: 0; left: 0;
		background: rgba(0,0,0,0.7);
	}

	.content{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 450px;
		@include transform(-50%, -50%);
	}

	h1{
		color: #ffffff;
		text-align: center;
	}
	p{
		color: #ffffff;
		text-align: center;
		padding-right: 50px;
		padding-left: 50px;
		margin-bottom: 25px;
	}
	form{
		float: left;
		position: relative;
		width: 100%;
		padding: 25px 50px 75px 50px;
		box-sizing: border-box;
		background: #ffffff;

		label{
			margin-top: 20px;
			margin-bottom: 15px;
			display: block;
			text-transform: uppercase;
			font-weight: $bold;
			font-size: 11px;
			letter-spacing: 1px;
			color: $dark-grey;
		}

		input,
		textarea{
			width: 325px;
			background: #eeeeee;
			border: 1px solid #eeeeee;
			border: none;
			padding: 10px;
			font-size: 12px;
			color: #333333;
			transition: none;

			&:focus{
				border: 1px solid #c1c1c1;
			}
		}

		input[type="submit"]{
			background: darken($grey, 20%);
			width: 150px;
			color: #ffffff;
			text-transform: uppercase;
			font-weight: $bold;
			letter-spacing: 1px;
			left: 52%;
			bottom: 30px;
			position: absolute;
			cursor: pointer;
			@include transform(-50%, 0);

			&:hover{
				background: $orange;
			}
		}
	}
}
.reg-hero{
	min-height: 1250px;
}
.double-content{
	background: #ffffff;
	min-height: 800px;
	position: relative;
	float: left;

	.text-content{
		box-sizing: border-box;
		width: 100%;
		padding-top: 50px;
		padding-right: 500px;
		padding-bottom: 150px;
		padding-left: 50px;

		strong{
			font-weight: $bold;
			font-size: 15px;
			line-height: 26px;
			display: block;
			margin: 40px 0 20px 0;
		}

		p a{
			color: darken($grey, 10%);
			display: inline-block;
			border-bottom: 1px solid $grey;

			&:hover{
				color: $orange;
				border-bottom: 1px solid $orange;
			}

			i 		{margin-left: 5px;}
		}

		a.orange-cta{
			margin-top: 50px;
		}
	}
	.related-content{
		border-left: 1px solid $line-grey;
		width: 450px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		nav{
			float: left;
			width: 100%;

			a{
				display: inline-block;
				float: left;
				width: 100%;
				text-align: center;
				padding: 30px 0;
				color: $grey;
				font-size: 13px;
				text-transform: capitalize;
				font-weight: $bold;
				border-bottom: 1px solid $line-grey;
				
				&:hover,
				&.active{
					color: #333333;
				}

				&:first-child{
					border-right: 1px solid $line-grey;
				}
				&:last-child{
					margin-left: -1px;
				}
			}
		}

		.top-innovators,
		.top-institutes{
			float: left;
			width: 100%;

			ul{
				float: left;
				width: 100%;
			}

			li{
				float: left;
				width: 100%;
				position: relative;
				border-bottom: 1px solid $line-grey;

				&:last-child{
					border-bottom: none;
				}
			}

			a{
				float: left;
				position: relative;
				width: 100%;
				height: 80px;
			}

			.headshot{
				position: absolute;
				height: 60px;
				width: 60px;
				top: 10px;
				left: 25px;
				background-size: cover !important;
				background-position: center !important;
				@include border-radius(50%);
			}
			.user-info{
				float: left;
				height: 80px;
				width: 100%;
				padding-top: 20px;
				padding-right: 20px;
				padding-left: 110px;
				box-sizing: border-box;

				strong{
					color: #333333;
					text-transform: capitalize;
					font-size: 14px;
					font-weight: $bold;
					display: block;
					margin-bottom: 10px;
				}

				span{
					font-size: 12px;
					color: $grey;
					display: inline-block;
					margin-right: 20px;

					&:last-child 	{margin-right: 0;}
				}
			}
		}

		.top-institutes{
			display: none;

			.headshot{
				@include border-radius(0);
				width: 110px;
			}

			.user-info 	{padding-left: 160px;}
		}
		
		.top-list{
			display: none;
			@include transition($slow);

			&.active{
				display: block;
			}
		}
	}
}
.disabled-cta{
	margin-top: 50px;
	display: block;
	float: left;
	background: #999999;
	color: #ffffff;
	text-transform: uppercase;
	padding: 12px 25px;
	font-size: 12px;
	letter-spacing: 1px;
	font-family: "Open Sans", sans-serif;
	font-weight: 800;
	border: none;
	transition: all 0.2s ease;
	cursor: pointer;
	text-align: center;
	line-height: 20px;
}
.challenge-stats{
	margin: 20px 0 50px 0;
	float: left;
	position: relative;
	width: 100%;

	li{
		float: left;
		width: 25%;

		strong{
			float: left;
			width: 100%;
			margin-bottom: 5px;
			font-weight: $bold;
			font-size: 26px;
			color: #333333;
			position: relative;

			.dot{
				position: absolute;
				width: 10px;
				height: 10px;
				top: 50%;
				left: -20px;
				transform: translateY(-50%);
				@include border-radius(50%);

				&.green 	{background: $green;}
				&.orange 	{background: $orange;}
				&.red 		{background: $red;}
				&.disabled {background: #999999;}
			}
		}
		span{
			color: $grey;
			font-size: 13px;
		}

		&:first-child{
			margin-left: 15%;
		}
	}
}
.challenge-details{
	position: relative;
	float: left;
	width: 100%;

	li{
		width: 100%;
		float: left;
		padding: 5px 0;

		p{
			display: inline-block;
		}

		strong{
			font-weight: $bold;
			color: $grey;
			font-size: 13px;
		}
	}
}
footer{
	background: #272727;
	color: #9e9e9e;
	position: relative;

	&.e-padding{
		margin-top: 150px;
	}

	.footer-cta{
		background: #363636;
		position: relative;

		.container{
			position: relative;
		}
		
		h6{
			position: relative;
			text-align: center;
			padding: 60px 0;
			font-family: $para;
			color: rgb(255,255,255);
			font-weight: $bold;
			font-size: 24px;
		}

		a{
			display: inline-block;
			position: absolute;
			padding: 15px 25px;
			text-transform: uppercase;
			color: #ffffff;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background: #ee271e;
			z-index: 100;
			font-weight: $fat;
			font-family: $para;
			font-size: 12px;
			letter-spacing: 1px;
		}
	}

	.absolute-logo{
		float: left;
		width: 100%;

		img{
			width: 65%;
		}
	}

	.footer-content{
		padding: 50px 0;
		float: left;
		width: 100%;

		.contact{
			float: left;
			width: 25%;

			p{
				font-size: 14px;
				font-family: $para;
				font-weight: $normal;
				color: rgba(255,255,255,0.7);
				line-height: 30px;
			}

			a 		{color: #ffffff;}
			i 		{margin-right: 5px;}
		}
		.menu{
			float: left;
			position: absolute;
			width: 100%;
			text-align: center;

			ul.main{				
				&>li{
					display: inline-block;
					position: relative;

					&:hover ul.hidden-menu{
						display: block;
					}
				}

				ul.hidden-menu{
					position: absolute;
					display: none;
				}
			}

			a{
				display: inline-block;
				padding: 5px 20px;
				font-size: 16px;
				font-family: $para;
				color: rgba(255,255,255,0.7);
				line-height: 30px;
				font-weight: $bold;
				position: relative;

				&:hover{
					color: #ffffff;
				}
			}
		}
		.social{
			position: relative;
			z-index: 10;
			margin-top: 10px;
			float: right;
			width: 15%;
			text-align: right;

			a{
				color: #ffffff;
				display: inline-block;
				padding: 5px 10px;

				&:first-child{
					margin-right: 15px;
				}
			}
		}
	}

	.copyright{
		margin-top: 25px;
		color: #575757;
		text-align: right;
		
		p{
			font-size: 12px;
			line-height: 20px;
		}
		a 	{color: #ffffff;}
	}
}

.form-group label{
	display: block;
	width: 100%;
	margin-top: 25px;
	margin-bottom: 10px;
}
.form-group small{
	font-size: 12px;
	color: #666666;
	display: block;
	width: 60%;
	line-height: 18px;
}
span.help-block{
	display: block;
	width: auto;

	p{
		color: red;
		margin: 0;
		font-size: 10px;
		padding: 0;
		text-align: left;
	}
}
.ratio-group{
	border: 1px solid red;
	float: left;
	width: 50%;
}
input[type="checkbox"],
input[type="radio"]{
	display: none;
}
.btn-primary{
	margin-top: 10px;
	padding: 10px 20px;
	color: #ffffff;
	background: #999999;
	cursor: pointer;
	border: none;
}

#login-panel{
	input[type="text"],
	input[type="email"],
	input[type="password"]{
		width: 450px;
		padding: 10px 15px;
		border: none;
		border-bottom: 1px solid $grey;
	}
}

.forgot-link{
	font-size: 12px;
	color: $grey;

	&:hover{
		color: $orange;
	}
}

.form-buttons{
	text-align: center;
	margin-top: 25px;
	margin-bottom: -50px;
	
	.btn{
		border: none;
		background: darken($grey, 10%);
		display: inline-block;
		padding: 10px 35px;
		font-size: 12px;
		cursor: pointer;
		text-transform: uppercase;
		font-weight: $bold;
		letter-spacing: 1px;
		line-height: 16px;
		color: #ffffff;

		&:hover{
			background: darken($grey, 20%);
		}

		&.focus{
			background: $orange;

			&:hover{
				background: darken($orange, 10%);
			}
		}
	}

}
.col-md-8 {
	width: 66%;
	display: inline-block;
}

.col-md-4 {
	width: 33%;
	display: inline-block;
}
form.filter-form{
	float: left;
	width: 100%;
	margin-bottom: 25px;

	select{
		border: 1px solid lighten($grey, 50%);
		background: #ffffff;
		padding-top: 5px;
		font-size: 11px;
		padding-right: 30px;
		padding-bottom: 5px;
		padding-left: 10px;
		background: url('../images/pull-down.svg') no-repeat;
		background-size: 12px auto;
		background-position: center right 5px;
		-webkit-appearance: none;
   		-moz-appearance: none;
   		     appearance: none;
		@include border-radius(0);
	}

	input.number{
		padding: 5px 10px;
		max-height: 25px;
		font-size: 11px;
	}

	.orange-cta{
		padding: 5px 10px;
		margin: 0;
	}
}
.dash-section{
	box-sizing: border-box;
	float: left;
	width: 100%;
	margin-bottom: 50px;
	padding: 25px 25px;
	border-radius: 5px;
	background: #ffffff;
	box-shadow: 0 0 1em #cccccc;

	h3{
		color: $grey;
		font-size: 20px;
		margin-bottom: 25px;
	}

	strong{
		font-weight: 700;
		font-size: 12px;
		display: block;
		margin-top: 5px;
		margin-bottom: 10px;
	}
	
	p.empty-text 		{margin: 20px 0;}

	.orange-cta{
		border: none;
		float: right;
		padding: 5px 10px;
		font-size: 11px;
		cursor: pointer;
		margin-top: 10px;
	}
	.orange-cta.left{
		float: left;
	}

	.form-group{
		margin-bottom: 15px;

		label{
			margin-bottom: 5px;
		}
	}
	
	.search-panel a{
		border-bottom: 1px solid #e5e5e5;
		display: block;
		padding: 20px 0;
		width: 100%;
		color: #666666;
		transition: all .2s ease;
		
		span{
			float: right;
			font-size: 14px;
		}

		&:hover{color: $orange;}

		&:last-child{border: none;}
	}

	.form-buttons{
		text-align: left;
	}

	.content{
		float: left;
		width: 100%;
		margin-top: 25px;
		padding: 25px 0;
		border-top: 1px solid #e5e5e5;
	}
	.btn-submit{
		margin-top: 0;
		margin-left: 0;
	}

	.info-section{
		float: left;
		width: 100%;
		margin-bottom: 25px;

		h3 	{margin-bottom: 10px;}

		.sender-info{
			li{
				font-size: 14px;
				color: #000000;
			}

			strong {
				display: inline-block;
				font-size: 14px;
				width: 100px;
				color: $grey;
			}

			a 	{color: $orange;}
		}

		a.view-project{
			display: inline-block;
			float: right;
			background: $orange;
			color: #ffffff;
			padding: 10px 15px;
			text-transform: uppercase;
			font-size: 12px;
			transition: all .25s ease;

			&:hover{background: darken($orange, 10%);}
		}
	}

	table{
		// border: 1px solid red;
		margin: 0;

		tbody{
			tr{
				background: #ffffff;
				border-top: 1px solid #E5E5E5;
			}

			td{
				color: #666666;

				&.right 		{text-align: right;}
				&.center 	{text-align: center;}

				.fa-star{color: $orange;}
			}

			p{
				font-size: 12px;
				color: #666666;
				display: block;
				width: 60%;
				line-height: 18px;
			}

			form 	{float: right;}

			a:hover 	{color: $orange;}
		}
	}
}

.admin-single-challenge{
	h2{
		padding-top: 25px;
		margin-bottom: 15px;
	}
	.pitch-cover{
		height: 350px;
		background-size: cover !important;
		background-position: center !important;
		margin-bottom: 25px;
	}
}

@import 'responsive';